:root {
  --green-primary: #256f44;
  --green-secondary: #154f2d;
  --green-tertiary: #152519;
}

/* You can add global styles to this file, and also import other style files */
input.ng-invalid.ng-touched, select.ng-invalid.ng-touched {
  border: red 1px solid;
}
